import React, { FC, useEffect, useState } from 'react';
import MonacoEditor, { MonacoEditorProps } from 'react-monaco-editor';
import dedent from 'dedent'
import { Range as MonacoRange } from 'monaco-editor';
import type * as monaco from 'monaco-editor';
import _package from './Package.svg';
import _function from './Function.svg';
import caret from './Caret.svg';

import { isAllowed, nil, toLookup, useElementSize } from './utils';
import { CompiledVaultOperation, callVault, HexString, VaultConfig, availableFunctions, FnDesc, RequiredApprove, } from '@nested-finance/sdk/web';
import type { Net } from './config';
import { useRecoilValue } from 'recoil';
import { allCollapsedAtom } from './atom';
// var compiler = new Worker(new URL('./compiler.ts', import.meta.url));

export interface PlaygroundCfg {
    vaultAddress: HexString;
    net: Net;
}

interface PlaygroundProps extends PlaygroundCfg {
    code: string;
    myAddress: HexString | null;
    codeChange: (code: string) => any;
    onStatusChange?: (result: CompilationResult) => any;
    error: ErrorDef | nil;
}

export type CompilationResult =
    | { type: 'compiling' }
    | { type: 'compiled'; result: CompiledVaultOperation; approvesRequired: RequiredApprove[]; }
    | { type: 'compilation failed';  } & ErrorDef;

export interface ErrorDef {
  error: string;
  position?: CodePosition
}
export interface CodePosition  {
  from: CodePoint;
  to: CodePoint;
}

export interface CodePoint {
  line: number;
  column: number;
}
type FnTree
    = FnLeaf
    | FnNode;
type FnLeaf = { type: 'leaf'; name: string; value: FnDesc };
type FnNode = { type: 'node'; name: string; children: FnTree[] };

const _fns = availableFunctions();

function buildNode(nodeName: string, fnList: [string, FnDesc][]): FnNode {
    const mapped = fnList.map(([fullName, fn]) => {
        const [, ns, name] = /^(\w+)\.(.+)/.exec(fullName) ?? [null, null, fullName];
        return {
            ns: ns,
            name,
            fn,
        } as const;
    });

    const lookup = toLookup(mapped, x => x.ns ?? null);
    const fns: FnTree[] = [
        ...lookup
            .get(null)
            ?.map<FnLeaf>(({ name, fn }) => ({
                type: 'leaf',
                name: name!,
                value: fn,
            })) ?? [],
        ...[...lookup.entries()]
            .filter(([k]) => !!k)
            .map(([k, values]) => buildNode(k!, values.map(x => [x.name!, x.fn!])))
    ].sort((a, b) => a.name > b.name ? 1 : -1);

    return { type: 'node', name: nodeName, children: fns };
}

export const fns = buildNode('root', _fns.map(x => [x.name, x])).children;


export function DocTree({ node }: { node: FnTree }) {
    if (node.type === 'leaf') {
        return <DocLeaf fn={node.value} />
    } else {
        return <DocNode node={node} />
    }
}

function DocLeaf({ fn }: { fn: FnDesc }) {
    const collaspe = useRecoilValue(allCollapsedAtom);
    const [open, setOpen] = useState(false);

    useEffect(() => setOpen(false), [collaspe]);

    return (
      <div className="flex flex-col gap-4">
        <div
          onClick={() => setOpen(!open)}
          className="cursor-pointer flex p-3 rounded-2xl bg-surface-muted gap-3 items-center"
        >
          <img src={_function} />
          <div className="text-base font-medium flex-1 flex gap-4">
            <span className="flex-1">{fn.name}</span>
            {fn.overloads.length > 1 ? (
              <span className="text-font-variant">
                {fn.overloads.length} overloads
              </span>
            ) : null}
          </div>
          <img src={caret} className={open ? "rotate-180" : ""} />
          <img />
        </div>
        {open && (
          <ul className="flex flex-col pl-4 border-l border-outline gap-4">
            {fn.overloads.map((o) => (
              <li key={o.args.join(",")} className="rounded-2xl bg-surface-variant-muted p-3 text-sm">
                <div className="text-font-variant whitespace-pre-line">
                  {o.desc}
                </div>
                <div className="p-2">
                  <span className="text-accent">{fn.name}</span>
                  {!o.args.length ? (
                    "()"
                  ) : (
                    <>
                      (<br />
                      {o.args.map((a) => (
                        <span key={a.name}>
                          <span className="ml-5 text-gray-500">
                            // {a.desc}:
                          </span>
                          <br />
                          <span className="ml-5">
                            {a.name}:
                            <span className="text-accent"> {a.type}</span>
                          </span>
                          <br />
                        </span>
                      ))}
                      )
                    </>
                  )}
                  {!o.args.length ? (
                    <div className="text-gray-500 ml-5"> → {o.returns}</div>
                  ) : (
                    <span className="text-gray-500"> → {o.returns}</span>
                  )}
                </div>
                {o.offchain && (
                  <div className="italic text-gray-500 px-2 whitespace-normal">
                    nb: this is an offchain function, it will be compiled to a
                    constant
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
}

function DocNode({ node }: { node: FnNode }) {
  const collaspe = useRecoilValue(allCollapsedAtom);
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(false), [collaspe]);

  return (
    <div className="flex flex-col gap-4">
      <div
        onClick={() => setOpen(!open)}
        className="cursor-pointer flex p-3 rounded-2xl bg-surface-muted gap-3 items-center"
      >
        <img src={_package} />
        <span className="text-base font-medium flex-1">{node.name}</span>
        <img src={caret} className={open ? "rotate-180" : ""} />
        <img />
      </div>
      {open && (
        <div className="flex flex-col pl-4 border-l border-outline gap-4">
          {node.children.map((n) => (
            <DocTree key={n.name} node={n} />
          ))}
        </div>
      )}
    </div>
  );
}

export function Editor(props: PlaygroundProps) {
  const [ref, { height }] = useElementSize();

  // typescript is wrong...
  const EE = EEditor as unknown as FC<PlaygroundProps & { height: number }>;
  return (
    <div className="h-full w-full" ref={ref}>
      <EE {...props} height={height} />
    </div>
  );
}

const editorLanguage = "sol";

interface State {
}
type CP = PlaygroundProps & { height: number; };
class EEditor extends React.Component<PlaygroundProps & { height: number; }, State> {
    private editor?: monaco.editor.ICodeEditor;
    private monaco?: typeof monaco;
    private hoverProvider?: monaco.IDisposable;
    private timeout: any;
    private code: string;
    constructor(props: CP) {
        super(props);

        this.state = { status: 'compiling' }
        // swap0x($USDT, 1000000, $USDC, 3%);
        this.code = props.code || dedent`
        #use vault;

        // deposit some usdc from wallet
        deposit(0.01 usdc);
        usdc =  balance($USDC);
        log("My USDC balance is: ", usdc);
        price = 0.95 [usdt/usdc]; // we expect at least 1usdc = 0.95 usdt... but allow 5% slippage
        gotUsdt = dex.uniswapV2(usdc, usdc * price);
        log("... we got usdt: ", gotUsdt);
        withdraw(gotUsdt);

        // If your input is constant, you can also perform swaps via 0x or paraswap...
        //   1) you might get better prices
        //   2) you will avoid non existing liquidity pools
        //   3) the interface is more user-friendly, since you dont have to specify an expected output amount:
        // ex:
        //
        // dex.zerox(1 usdc, $DAI, 3%);
        `;
    }
    editorDidMount(editor: monaco.editor.ICodeEditor, m: typeof monaco) {
        this.editor = editor;
        this.monaco = m;
        editor.focus();

        this.monaco.languages.register({ id: editorLanguage });

        this.compile(this.code);
    }

    onChange(newValue: string) {
        if (newValue === this.code) {
            return;
        }
        this.code = newValue;
        this.props.codeChange(newValue);
        this.recompile();
    }

    componentWillReceiveProps(newProps: CP) {
        if (newProps.code !== this.code && newProps.code) {
            this.code = newProps.code;
            this.editor?.getModel()?.setValue(newProps.code);
        }

        this.updateError(newProps.error);
    }

    private updateError(e: ErrorDef | nil) {
      this.monaco?.editor?.setModelMarkers(
        this.editor?.getModel()!,
        'playground',

        e?.position ?[
          {
            startColumn: e.position.from.column,
            endColumn: e.position.to.column,
            startLineNumber: e.position.from.line,
            endLineNumber: e.position.to.line,
            message: e.error,
            severity: this.monaco.MarkerSeverity.Error,
          }
        ] : []
      );
    }


    recompile() {
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            globalError: null,
            status: 'compiling',
            bytecode: null,
            resultType: null,
        });
        this.timeout = setTimeout(() => this.compile(this.code), 300);
    }

    async compile(code: string) {
        this.props.onStatusChange?.({ type: 'compiling' });
        try {
            const cfg: VaultConfig = {
                vaultAddress: this.props.vaultAddress,
                rpcUrl: this.props.net.rpc,
                excludedDexes: ["Portals"]
            };

            const result = await callVault(cfg, code);
            if (code !== this.code) {
                // concurrency
                return;
            }

            // count approves
            let approvesRequired: RequiredApprove[] = [];
            const ma = this.props.myAddress;
            if (ma) {
                for (const a of result.requiredApproves) {
                    if (!await isAllowed(a.token, ma, result.vaultAddress, a.knownAmount?.amount)) {
                        approvesRequired.push(a);
                    }
                }
            }
            if (code !== this.code) {
                // concurrency
                return;
            }

            this.props.onStatusChange?.({ type: 'compiled', result, approvesRequired });

            // clear old hover provider
            this.hoverProvider?.dispose();

            // register a hover provider to display compiled metadata
            this.hoverProvider = this.monaco?.languages.registerHoverProvider(editorLanguage, {
              provideHover: function (_, position) {
                const found = result.metadata.find(m => 
                  position.lineNumber === m.loc.start.line 
                    && position.column >= m.loc.start.column 
                    && position.column <= m.loc.end.column
                  );
                if (found) {
                  return {
                    range: new MonacoRange(
                      found.loc.start.line,
                      found.loc.start.column,
                      found.loc.end.line,
                      found.loc.end.column
                    ),
                    contents: [
                      { value: "```ts\ndex: " + found.dex + "\n```" },
                      { value: "```ts\nprice: " + found.price + "\n```" },
                      { value: "```ts\ninputAmount: " + found.inputAmount + "\n```" },
                      { value: "```ts\ninputTokenDecimals: " + found.inputTokenDecimals + "\n```" },
                      { value: "```ts\noutputAmount: " + found.outputAmount + "\n```" },
                      { value: "```ts\noutputTokenDecimals: " + found.outputTokenDecimals + "\n```" },
                    ],
                  };
                } else {
                  return undefined;
                }
              },
            });
        } catch (e) {
            let msg = (e as any).message as string;
            const [ok, begin, fromLine, fromCol, toLine, toCol] = /^(.+)\s+at\s+\((\d+):(\d+)\s+->\s+(\d+):(\d+)\)$/.exec(msg) ?? [];
            let position: CodePosition | undefined = undefined;
            if (ok) {
                position = {
                    from: { line: parseInt(fromLine), column: parseInt(fromCol) },
                    to: { line: parseInt(toLine), column: parseInt(toCol) },
                };
                msg = begin;
            }
            this.props.onStatusChange?.({ type: 'compilation failed', error: msg, position });
        }
    }

    render() {
        const options = {
            selectOnLineNumbers: true,
            glyphMargin: true,
        };
        // typescript is wrong...
        const ME = MonacoEditor as unknown as FC<MonacoEditorProps>;
        return <div className='rounded-2xl p-3 bg-[#1e1e1e]'>
            <ME
                width="100%"
                height={this.props.height - 24}
                language={editorLanguage}
                theme="vs-dark"
                value={this.code}
                options={options}
                onChange={this.onChange.bind(this)}
                editorDidMount={this.editorDidMount.bind(this)}
            />
        </div>
    }
}
