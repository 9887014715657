import { HexString } from "@nested-finance/sdk/web";
import { RecoilRoot } from 'recoil';
import React from "react";
import { render } from "react-dom";
import "./index.css";
import { Playground } from "./playground";
import { useHash, useNet } from "./utils";
import { Welcome } from "./Welcome";

declare var ethereum: any;

function App() {
  const [hash] = useHash();

  const net = useNet();

  return (
    <div className="h-screen w-screen overflow-auto bg-home-bg text-white p-6">
      {!hash || !net ? (
        <Welcome />
      ) : (
        <Playground
          vaultAddress={hash.replace("#", "") as HexString}
          net={net}
        />
      )}
    </div>
  );
}

render(<RecoilRoot><App /></RecoilRoot>, document.getElementById("root"));
