import { Chain, arbitrum, avalanche, bsc, mainnet, optimism, polygon } from 'viem/chains'

export const chainIds: Record<string, Net> = {
    '0x89': {
        name: 'Polygon',
        rpc: `https://polygon-rpc.com`,
        apiId: 'poly',
        chain: polygon
    } as const,
    '0x1': {
        name: 'Ethereum',
        rpc: `https://cloudflare-eth.com`,
        apiId: 'eth',
        chain: mainnet
    } as const,
    '0xa4b1': {
        name: 'Arbitrum',
        rpc: `https://rpc.ankr.com/arbitrum`,
        apiId: 'arbi',
        chain: arbitrum
    },
    '0xa86a': {
        name: 'Avalanche',
        rpc: `https://api.avax.network/ext/bc/C/rpc`,
        apiId: 'avax',
        chain: avalanche
    },
    '0xa': {
        name: 'Optimism',
        rpc: `https://mainnet.optimism.io`,
        apiId: 'opti',
        chain: optimism
    },
    '0x38':  {
        name: 'BNB Chain',
        rpc: 'https://bsc-dataseed.binance.org/',
        apiId: 'bsc',
        chain: bsc
    }
};

export interface Net {
    name: string;
    rpc: string;
    apiId: string;
    chain: Chain
}
