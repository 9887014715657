import type { HexString } from '@nested-finance/sdk/web';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';
import { chainIds, Net } from './config';
import { BigNumber, Contract, providers, utils } from 'ethers';

export function useHash() {
  const [hash, setHash] = useState(() => window.location.hash);

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  }, []);

  const updateHash = useCallback(
    newHash => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash]
  );

  return [hash, updateHash] as const;
}



export interface Size {
  width: number
  height: number
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth])

  useEventListener('resize', handleSize)

  useIsomorphicLayoutEffect(() => {
    handleSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth])

  return [setRef, size]
}

declare var ethereum: any;
export function useNet(): Net | null {
  const [result, setResult] = useState<Net | null>(null);
  useEffect(() => {
    (async () => {
      try {
        if (typeof ethereum === 'undefined') {
          throw new Error('Metamask not detected');
        }
        const chainId = await ethereum.request({ method: 'eth_chainId' });
        const toNet = chainIds[chainId];
        if (!toNet) {
          throw new Error('Unsupported chain ' + chainId);
        }
        setResult(toNet);
      } catch (e) {
        console.error('Failed to detect network', e);
      }
    })();
  });
  return result;
}

export function useMyAddress(): HexString | null {
  const [result, setResult] = useState<HexString | null>(null);
  useEffect(() => {
    (async () => {
      try {
        if (typeof ethereum === 'undefined') {
          throw new Error('Metamask not detected');
        }
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        const from = ethereum.selectedAddress ?? accounts[0];
        setResult(from as HexString);
      } catch (e) {
        console.error('Failed to detect my address', e);
      }
    })();
  });
  return result;
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (deepEqual(value, debouncedValue)) {
        return;
      }
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  });

  return debouncedValue;
}

export function deepEqual<T>(obj1: T, obj2: T) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  }
  // Private
  function isObject(obj: any): obj is object {
    if (typeof obj === 'object' && obj != null) {
      return true;
    } else {
      return false;
    }
  }
}



export function by<TKey, TOrig, TTarget>(
  col: TOrig[],
  key: (val: TOrig) => TKey,
  value: (val: TOrig) => TTarget,
): Map<TKey, TTarget>;
export function by<TKey, T>(col: T[], key: (val: T) => TKey, overrideDuplicates?: boolean): Map<TKey, T>;
export function by(col: any[], key: (val: any) => string, valOrKey?: boolean | ((val: any) => any)): Map<any, any> {
  const ret = new Map();
  const val = typeof valOrKey === 'boolean' || !valOrKey ? (x: any) => x : valOrKey;
  const ovr = valOrKey === true;
  for (const v of col) {
    const k = key(v);
    if (!ovr && ret.has(k)) {
      throw new Error(`Duplicate key "${k}"`);
    }
    ret.set(k, val(v));
  }
  return ret;
}

export function toLookup<T, TKey>(
  array: readonly T[],
  keySelector: (item: T, idx: number) => TKey,
): Map<TKey, T[]> {
  const map = new Map<TKey, T[]>();
  let i = 0;
  for (const a of array) {
    const key = keySelector(a, i++);
    const arr = map.get(key);
    if (arr) {
      arr.push(a);
    } else {
      map.set(key, [a]);
    }
  }
  return map;
}

export function toNumber(num: BigNumber, decimals: number): number {
  const str = utils.formatUnits(num, decimals);
  return parseFloat(str);
}

export type nil = null | undefined;

export function nullish(val: any): val is nil {
  return val === null || val === undefined;
}

export function notNil<T>(value: (T | nil)[]): Exclude<T, null>[] {
  return value.filter(x => !nullish(x)) as any[];
}



const provider = new providers.Web3Provider(ethereum, "any");
const ERC20minimal = new utils.Interface([
  { "constant": true, "inputs": [], "name": "decimals", "outputs": [{ "name": "", "type": "uint8" }], "payable": false, "stateMutability": "view", "type": "function" },
  { "constant": true, "inputs": [{ "name": "", "type": "address" }, { "name": "", "type": "address" }], "name": "allowance", "outputs": [{ "name": "", "type": "uint256" }], "payable": false, "stateMutability": "view", "type": "function" },
]);

export async function isAllowed(tokenAddress: HexString, owner: HexString, spender: HexString, amt: bigint | nil): Promise<boolean> {
  const token = new Contract(tokenAddress, ERC20minimal, provider);
  const allowance: BigNumber = await token.allowance(owner, spender);
  let amtBig = amt && BigNumber.from(amt);
  if (!amtBig) {
    const digits = 10 + await token.decimals();
    amtBig = BigNumber.from(10).pow(digits);
  }
  return allowance.gte(amtBig);
}