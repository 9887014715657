import { createVault } from "@nested-finance/sdk/web";
import React from "react";
import { render } from "react-dom";
import { Btn } from "./Btn";
import { chainIds } from "./config";
import "./index.css";
import { ButtonTxt } from "./Txt";
import { useHash, useMyAddress, useNet } from "./utils";
import logo from "./mass.svg";

declare var ethereum: any;

export function Welcome() {
  const [_, setHash] = useHash();

  const toNet = useNet();

  const myAddress = useMyAddress();

  const doCreateNewVault = async () => {
    if (!toNet || !myAddress) {
      return;
    }

    const { tx, vaultAddress } = await createVault({
      creatorAddress: myAddress,
      rpcUrl: toNet.rpc,
    });
    const val = {
      method: "eth_sendTransaction",
      params: [
        {
          // nonce: '0x00', // ignored by MetaMask
          // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
          // gas: '0x2710', // customizable by user during MetaMask confirmation.
          ...tx,
          from: myAddress,
          value: "0x00",
        },
      ],
    };
    console.log(val);
    await ethereum.request(val);

    gotoAddress(vaultAddress);
  };

  const gotoAddress = (address: string) => {
    setHash(address);
  };

  return (
    <div className="flex flex-col h-full w-full items-center justify-center">
      <div className="flex flex-col p-8 bg-surface rounded-3xl gap-20">
        <div className="flex flex-col items-center gap-6">
          <img src={logo} />
          <div className="text-4xl leading-[48px] font-youth font-black">
            Mass SDK Playground
          </div>
        </div>
        <div className="flex flex-col items-center gap-4">
          <Btn className="w-full" onClick={doCreateNewVault}>
            Create a new vault
          </Btn>
          <div className="flex items-center gap-2 w-full">
            <div className="bg-outline rounded-sm h-px flex-1" />
            <span className="text-base font-normal text-font-variant">Or</span>
            <div className="bg-outline rounded-sm h-px flex-1" />
          </div>
          <ButtonTxt
            onValidate={gotoAddress}
            placeholder="You vault address (0x....)"
          ></ButtonTxt>
        </div>
      </div>
    </div>
  );
}
